html,
body,
#root {
  margin: 0;
  padding: 0;
}

/* react-toast behavior */
.toast-container {
  z-index: 5001;
  /* Set the desired z-index value */
}

.blue-progress-bar {
  background: rgb(21 128 61);
}

.Toastify__close-button {
  position: absolute;
  top: 50%;
  right: 12px;
  font-size: 16px;
  transform: translateY(-50%);
  color: inherit;
  /* Inherit color from parent */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input:disabled,
button:disabled,
select:disabled,
textarea:disabled {
  cursor: not-allowed;
}

@keyframes zoom {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }
}

.animate-zoom {
  animation: zoom 1s infinite;
}

.animate-zoom:hover {
  animation: none;
  cursor: pointer;
}

.custom-list-style::before {
  content: "-";
  margin-right: 6px;
}