/* DonutChart.css */
.chart-container {
  display: flex;
}

.chart-wrapper {
  flex: 3;
}

.legend-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.legend-list li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  /* Adjust margin for spacing between items */
}

.legend-list li span {
  display: inline-block;
  margin-right: 8px;
}
