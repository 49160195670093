.main-content {
  width: calc(100% - 105px);
  float: inline-end;
}

@media (max-width: 767px) {
  .main-content {
      width: 100%;
  }
}
