@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
  }
}

.primary_btn {
    background-color: #447E42 !important;
    color: white !important;
    border-radius: 8px !important;
    border: none !important;
}

.secondary_btn {
    color: black !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    border: none !important;
}

.tour_step {
    border-radius: 12px !important;
}

.rounded-container {
    border-radius: 12px;
}

.step_counter {
    background-color: transparent !important;
    color: gray !important;
    font-weight: normal;
    font-size: 14px;
    border: none !important;
    margin-right: auto !important; 
    cursor: default !important;
  }

  .primary_btn,
  .secondary_btn {
    margin-left: 8px;
  }

  .tour_step {
    border-radius: 12px !important;
  }
